import useSWR from 'swr';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Image, Input, Link, NumberInput, Text, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import ErrorMessageIcon from '../../../assets/Icons/ErrorMessageIcon';
import HeartIcon from '../../../assets/Icons/HeartIcon';
import { NumberKeys } from '../../../constants';
import { ModalType } from '../../../enum/ModalType';
import { SWRKey } from '../../../enum/SWRKey';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { getProductsById } from '../../../services/product';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { authData, getUserPriceList } from '../../../store/modules/auth';
import { addItem } from '../../../store/modules/cart';
import { getCartProductQuantity, getCartProducts } from '../../../store/modules/cart/selectors';
import { CartItem } from '../../../store/modules/cart/types';
import {
  addProductFavorite,
  deleteFavorite,
  isFavoriteProduct,
  isLoadingFavorites,
} from '../../../store/modules/favorites';
import {
  getQuotations,
  isLoadingQuotations,
  quotationsData,
} from '../../../store/modules/quotations';
import { showModal } from '../../../store/modules/ui/slice';
import { RootState } from '../../../store/types';
import { calculateRemainingStock } from '../../../utils/calculateRemainingStock';
import { getCurrencyConversion } from '../../../utils/getCurrencyConversion';
import { priceFormatter, priceFormatterNumber } from '../../../utils/priceFormatter';
import { cutString, transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';
import Button from '../../shared/Button/Button';
import ErrorScreen from '../../shared/ErrorScreen/ErrorScreen';
import GoBack from '../../shared/GoBack/GoBack';
import ToastWarningAlert from '../Favorite/ToastWarningAlert';
import FavoriteTooltip from './FavoriteTooltip';
import ProductViewSkeleton from './ProductViewSkeleton';
import { AddProductToCartValidation } from './validations';
interface QuantitySelection {
  quantitySelection: number;
}

const ProductView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const cartItems = useAppSelector(getCartProducts);
  const userPriceList = useAppSelector(getUserPriceList);

  const {
    data: product,
    error: productByIdError,
    isLoading,
  } = useSWR({ productId: id as string, key: SWRKey.product }, getProductsById);

  const [bgIcon, setBgIcon] = useState(false);

  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);

  const { isMobile } = useBreakpoint();
  const toast = useToast();

  const { isLogged } = useAppSelector(authData);
  const quotesData = useAppSelector(quotationsData);
  const loadingQuotations = useAppSelector(isLoadingQuotations);
  const loadingFavorites = useAppSelector(isLoadingFavorites);
  const favoriteProduct = useAppSelector((state: RootState) =>
    isFavoriteProduct(state, id as string),
  );

  const cartProductQuantity = useAppSelector((_state: RootState) =>
    getCartProductQuantity(_state, id as string),
  );

  const availableStock = useMemo(() => {
    if (!product && isLoading) return null;

    const stock = calculateRemainingStock(product, cartProductQuantity);

    return stock;
  }, [cartProductQuantity, isLoading, product]);

  const { register, watch, handleSubmit, formState, setError } = useForm<QuantitySelection>({
    resolver: yupResolver(AddProductToCartValidation(availableStock?.totalStock ?? 0)),
    mode: 'onChange',
  });
  const { errors } = formState;
  const stockError = errors.quantitySelection?.message === textContent.product.messageMaximum;
  const quantity = watch('quantitySelection');

  const handleAddToCart = (product: CartItem) => dispatch(addItem(product));

  const onSubmit = (data: QuantitySelection) => {
    if (product?.priceList === 0) {
      setError('quantitySelection', {
        type: 'manual',
        message: `${textContent.product.priceRequired}`,
      });
      return;
    }

    if (toastIsOpen) return;

    if (product && availableStock && cartItems.length < 10) {
      handleAddToCart({
        ...product,
        existence: product.existence ? +product.existence : 0,
        pendingToReceive: product.pendingToReceive ? +product.pendingToReceive : 0,
        totalStock: product.totalStock ? +product.totalStock : 0,
        quantity: +data.quantitySelection,
      });
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          items: [
            {
              item_name: product?.articleDesc,
              item_id: product?.articleCode,
              item_brand: product?.brand,
              item_category: product?.operatingCenterDesc,
              price: product?.priceList,
              quantity: +data.quantitySelection,
            },
          ],
        },
      });
      navigate('/cart');
      toast({
        description: textContent.product.productAddedToCart,
        status: 'success',
        variant: 'subtle',
        containerStyle: { fontSize: '14px' },
        duration: 3000,
      });
      setToastIsOpen(true);
    }
    if (cartItems.length === 10) {
      toast({
        id,
        duration: 3000,
        position: isMobile ? 'top' : 'bottom',
        onCloseComplete: () => setToastIsOpen(false),
        render: () => (
          <ToastWarningAlert
            title={textContent.product.cartItemsQuantityErrorTitle}
            subTitle={textContent.product.cartItemsQuantityError}
          />
        ),
      });
      setToastIsOpen(true);
    }
  };

  const handleFavoriteProduct = () => {
    if (!product) {
      return;
    }
    setAlertIsOpen(true);
    setTimeout(() => {
      setAlertIsOpen(false);
    }, 1000);

    if (!favoriteProduct && !bgIcon) {
      dispatch(addProductFavorite(product));
      setBgIcon(true);
      return;
    }
    dispatch(deleteFavorite({ productId: product.articleCode }));
    setBgIcon(false);
  };

  useEffect(() => {
    dispatch(getQuotations());
  }, [dispatch]);

  useEffect(() => {
    if (product) {
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          items: [
            {
              item_name: product?.articleDesc,
              item_id: product?.articleCode,
              item_brand: product?.brand,
              item_category: product?.operatingCenterDesc,
              price: product?.priceList,
            },
          ],
        },
      });
    }
  }, [id, product]);

  const RenderStock = useMemo(() => {
    if (!isLogged) return <></>;

    if (Number(product?.totalStock ?? '0') !== 0) {
      return (
        <>
          {userPriceList === '001' && (
            <>
              <Text
                textAlign="start"
                color={stockError ? 'errorRed' : 'darkBlue'}
                fontSize={isMobile ? '14px' : '18px'}
                fontWeight="500"
                fontFamily="Roboto"
                marginBottom="5px"
                data-testid="stock-available"
              >
                {textContent.branchAvailableStock} {availableStock?.existence}{' '}
                {`${transformString(product?.unitMeasureCode)}.`}
              </Text>
              <Text
                textAlign="start"
                color={stockError ? 'errorRed' : 'darkBlue'}
                fontSize={isMobile ? '14px' : '18px'}
                fontWeight="500"
                fontFamily="Roboto"
                marginBottom="5px"
                data-testid="stock-in-travel"
              >
                {textContent.onTripStock} {availableStock?.pendingToReceive}{' '}
                {`${transformString(product?.unitMeasureCode)}.`}
              </Text>
            </>
          )}
          <Text
            textAlign="start"
            color={stockError ? 'errorRed' : 'darkBlue'}
            fontSize={isMobile ? '14px' : '18px'}
            fontWeight="500"
            fontFamily="Roboto"
            marginBottom="24px"
            data-testid="total-stock"
          >
            {textContent.totalStock} {availableStock?.totalStock}{' '}
            {`${transformString(product?.unitMeasureCode)}.`}
          </Text>
        </>
      );
    }

    return (
      <Text
        textAlign="start"
        color="darkBlue"
        fontSize={isMobile ? '14px' : '18px'}
        fontWeight="500"
        fontFamily="Roboto"
        marginBottom="24px"
        data-testid="stock-available"
      >
        {textContent.noStock}
      </Text>
    );
  }, [
    availableStock?.existence,
    availableStock?.pendingToReceive,
    availableStock?.totalStock,
    isLogged,
    isMobile,
    product?.existence,
    product?.pendingToReceive,
    product?.totalStock,
    stockError,
    userPriceList,
    product?.unitMeasureCode,
  ]);

  if (isLoading || loadingQuotations || loadingFavorites) {
    return (
      <ProductViewSkeleton onClick={() => navigate('/')} isMobile={isMobile} isLogged={isLogged} />
    );
  }

  if (!product || productByIdError) {
    return (
      <ErrorScreen
        url={'/'}
        title={textContent.product.associatedProductsNotFound}
        subTitle={textContent.product.tryAnotherKeyWord}
      />
    );
  }

  return (
    <Flex backgroundColor="grey50" width="100%">
      <Flex justifyContent="flex-start" direction="column" width="100%" height="100vh">
        <GoBack
          title={transformString(product.brand)}
          subTitle={transformString(product.operatingCenterDesc)}
        />
        <Box
          borderRadius={isMobile ? '0' : '8px'}
          backgroundColor={{ base: 'grey50', md: 'background' }}
          border={isMobile ? 'none' : '0.48px solid #D5DDE0'}
          marginTop={isMobile ? '0' : '48px'}
          marginLeft={isMobile ? '0' : '112px'}
          marginRight={isMobile ? '0' : '112px'}
          marginBottom={{ base: '65px', md: '85px' }}
          minWidth="280px"
          data-testid="product-view-container"
        >
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={isMobile ? 'center' : 'flex-start'}
            margin={{ base: '16px 27px 0 21px', md: '32px 29px 3px 29px' }}
          >
            <Flex flexDirection="column" width={{ base: '90%', md: '325px' }}>
              <Flex
                marginTop="20px"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                height="215px"
                borderRadius="9.59px"
                border="0.48px solid #D5DDE0"
                marginRight={{ md: '32px' }}
              >
                <Flex
                  width="100%"
                  justifyContent="flex-end"
                  position="relative"
                  top="20px"
                  right="20px"
                >
                  {isLogged && isMobile && (
                    <FavoriteTooltip bgIcon={bgIcon} alertIsOpen={alertIsOpen}>
                      <Flex>
                        <HeartIcon
                          onClick={() => handleFavoriteProduct()}
                          dataTestId="heart-icon"
                          cursor="pointer"
                          bgIcon={favoriteProduct ? true : bgIcon}
                        />
                      </Flex>
                    </FavoriteTooltip>
                  )}
                </Flex>
                <Image
                  src={product.productImageUrl}
                  alt={transformString(product.articleDesc)}
                  width="293px"
                  height="212px"
                  borderRadius="9.59px"
                  data-testid="product-image"
                  objectFit="contain"
                  backgroundColor="white"
                />
              </Flex>
              {!isMobile && isLogged && (
                <Flex alignItems="center">
                  <FavoriteTooltip bgIcon={bgIcon} alertIsOpen={alertIsOpen}>
                    <Flex>
                      <HeartIcon
                        onClick={() => handleFavoriteProduct()}
                        dataTestId="heart-icon"
                        cursor="pointer"
                        bgIcon={favoriteProduct ? true : bgIcon}
                      />
                    </Flex>
                  </FavoriteTooltip>
                  <Text marginLeft="5px">
                    {favoriteProduct || bgIcon
                      ? textContent.deleteFavorite
                      : textContent.addFavourite}
                  </Text>
                </Flex>
              )}
            </Flex>
            <Box margin="16px 0" width={{ base: '90%', md: '40%' }}>
              {isLogged && (
                <Flex
                  justifyContent={isMobile ? 'space-between' : 'flex-start'}
                  alignItems={isMobile ? 'center' : 'baseline'}
                >
                  <Text
                    color="darkBlue"
                    fontSize={isMobile ? '18px' : '20px'}
                    fontWeight="600"
                    fontFamily="Roboto"
                    textAlign={{ base: 'start', md: 'center' }}
                    marginBottom="7px"
                    marginRight={isMobile ? '0' : '20px'}
                    data-testid="product-price"
                  >
                    <Text color="#1c5dc7" display="inline">
                      ARS{' '}
                    </Text>
                    {priceFormatterNumber.format(product?.priceList)}
                  </Text>
                </Flex>
              )}
              <Text
                textAlign="start"
                color="darkBlue"
                fontSize={isMobile ? '16px' : '18px'}
                fontWeight="400"
                fontFamily="Roboto"
                marginBottom={{ base: '8px', md: '16px' }}
                data-testid="individual-size"
              >
                {`${cutString(product.articleDesc?.toUpperCase())}.`}
              </Text>
              {RenderStock}
              {isLogged && (
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Box data-testid="quantity-selection">
                    <Text
                      color="darkBlue"
                      fontSize="14px"
                      fontWeight="500"
                      fontFamily="Roboto"
                      marginBottom="7px"
                      textAlign="start"
                    >
                      {textContent.quantitySelection}
                    </Text>
                    <NumberInput maxWidth={{ xl: '325px' }} width={{ base: '100%' }}>
                      <Input
                        width="100%"
                        height="40px"
                        borderRadius="6px"
                        border="1px solid #E2E8F0"
                        placeholder={`(${availableStock?.totalStock} disponibles)`}
                        fontFamily="Roboto"
                        fontSize="14px"
                        fontWeight="500"
                        color="grey900"
                        _disabled={{
                          color: 'grey700',
                          'pointer-events': 'none',
                          cursor: 'not-allowed',
                        }}
                        type="number"
                        maxWidth={{ xl: '325px' }}
                        backgroundColor="white"
                        {...register('quantitySelection')}
                        onKeyDown={(e) => !NumberKeys.includes(e.key) && e.preventDefault()}
                        data-testid="quantity-input"
                        onWheel={(event) => event.currentTarget.blur()}
                        disabled={availableStock?.totalStock === 0}
                      />
                    </NumberInput>
                    {errors.quantitySelection && (
                      <Flex alignItems="center" maxWidth="300px" width="100%" marginTop="8px">
                        <Box width="16px" height="16px">
                          <ErrorMessageIcon dataTestId="error-message-icon" />
                        </Box>
                        <Text
                          color="errorRed"
                          fontFamily="Roboto"
                          fontSize="12px"
                          fontWeight="500"
                          marginLeft="5px"
                        >
                          {errors.quantitySelection.message}
                        </Text>
                      </Flex>
                    )}
                    <Text
                      color="grey900"
                      fontSize="20px"
                      fontWeight="600"
                      fontFamily="Roboto"
                      textAlign="start"
                      margin="16px 0 4px 0"
                    >
                      {`Total ${
                        quantity > 0 ? priceFormatter.format(quantity * product?.priceList) : 0
                      }`}
                    </Text>
                    <Flex justifyContent="flex-start" marginBottom="32px">
                      <Text
                        color="black"
                        fontSize="14px"
                        fontWeight="500"
                        fontFamily="Roboto"
                        textAlign={{ base: 'start', md: 'center' }}
                        marginRight={isMobile ? '14px' : '30px'}
                        data-testid="product-price-in-usd"
                      >
                        USD{' '}
                        {quantity > 0
                          ? getCurrencyConversion(quotesData, quantity, product?.priceList, 'USD')
                          : 0}
                      </Text>
                      <Text
                        color="black"
                        fontSize="14px"
                        fontWeight="500"
                        fontFamily="Roboto"
                        textAlign={{ base: 'start', md: 'center' }}
                        marginRight={isMobile ? '14px' : '30px'}
                        data-testid="product-price-in-usdt"
                      >
                        USDT{' '}
                        {quantity > 0
                          ? getCurrencyConversion(quotesData, quantity, product?.priceList, 'T')
                          : 0}
                      </Text>
                      <Text
                        color="black"
                        fontSize="14px"
                        fontWeight="500"
                        fontFamily="Roboto"
                        textAlign={{ base: 'start', md: 'center' }}
                        marginRight={isMobile ? '14px' : '30px'}
                        data-testid="product-price-in-eur"
                      >
                        EURO{' '}
                        {quantity > 0
                          ? getCurrencyConversion(quotesData, quantity, product?.priceList, 'EU')
                          : 0}
                      </Text>
                      <Text
                        color="black"
                        fontSize="14px"
                        fontWeight="500"
                        fontFamily="Roboto"
                        textAlign={{ base: 'start', md: 'center' }}
                        marginRight={isMobile ? '0' : '30px'}
                        data-testid="product-price-in-bob"
                      >
                        BS{' '}
                        {quantity > 0
                          ? getCurrencyConversion(quotesData, quantity, product?.priceList, 'BO')
                          : 0}
                      </Text>
                    </Flex>
                    <Flex
                      justifyContent={isMobile ? 'center' : 'flex-start'}
                      alignItems="flex-end"
                      marginBottom="24px"
                      width="100%"
                      maxWidth={{ md: '325px' }}
                    >
                      <Button
                        color="grey50"
                        backgroundColor="purple"
                        height="48px"
                        marginTop="10px"
                        width={{ base: '100%', md: '325px' }}
                        _hover={{
                          backgroundColor: availableStock?.totalStock !== 0 && 'darkPurple',
                        }}
                        _active={{
                          backgroundColor: availableStock?.totalStock !== 0 && 'darkPurple',
                        }}
                        content={textContent.product.addProduct}
                        data-testid="add-product-button"
                        type="submit"
                        isDisabled={availableStock?.totalStock === 0}
                      />
                    </Flex>
                  </Box>
                </form>
              )}
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            margin={{ base: '0 38px', md: '0 29px' }}
            marginBottom={{ md: '40px' }}
          >
            <Text
              textAlign="start"
              color="darkBlue"
              fontSize={isMobile ? '14px' : '18px'}
              fontWeight="500"
              fontFamily="Roboto"
              marginBottom="7px"
              data-testid="product-description"
            >
              {textContent.product.productDescription}
            </Text>
            <Text
              textAlign="start"
              color="darkBlue"
              fontSize={isMobile ? '12px' : '16px'}
              fontWeight="400"
              fontFamily="Roboto"
              marginBottom="7px"
            >
              {product.description.toUpperCase()}
            </Text>
          </Flex>
          {!isLogged && (
            <Flex
              flexDirection="column"
              margin={isMobile ? '30px' : '100px 30px'}
              alignItems={{ md: 'center' }}
            >
              <Text
                fontSize={isMobile ? '13px' : '18px'}
                fontWeight="400"
                lineHeight="14px"
                data-testid="login-popover-text"
              >
                {textContent.loginPopoverText}
              </Text>
              <Flex justifyContent="center">
                <Text fontSize={isMobile ? '12px' : '14px'} fontWeight="400" paddingTop="25px">
                  {textContent.toBeClient}
                  <Link
                    href={`https://wa.me/5493525521001?text=${textContent.newClientCodeInfoWhatsappMessage}`}
                    isExternal
                    textDecoration="underline"
                    color="purple"
                    fontWeight="500"
                    data-testid="new-client-link"
                  >
                    {textContent.clickHere}
                  </Link>
                  .
                </Text>
              </Flex>
              <Flex justifyContent="center" width="100%">
                <Button
                  color="grey50"
                  backgroundColor="purple"
                  height={isMobile ? '34px' : '48px'}
                  fontSize="18px"
                  marginTop="10px"
                  _hover={{ backgroundColor: 'darkPurple' }}
                  _active={{ backgroundColor: 'darkPurple' }}
                  content={textContent.clientLogin}
                  data-testid="login-button"
                  onClick={() => {
                    dispatch(
                      showModal({
                        type: ModalType.LOGIN,
                      }),
                    );
                  }}
                />
              </Flex>
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
export default ProductView;
