import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

import DeleteProductIcon from '../../../assets/Icons/DeleteProductIcon';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { removeItem } from '../../../store/modules/cart';
import { CartItem } from '../../../store/modules/cart/types';
import { hideModal } from '../../../store/modules/ui/slice';
import { RootState } from '../../../store/types';
import textContent from '../../../utils/textContent.json';
import Button from '../../shared/Button/Button';
interface Props {
  isMobile: boolean;
}

const DeleteProduct = ({ isMobile }: Props) => {
  const dispatch = useAppDispatch();

  const { data } = useAppSelector((state: RootState) => state.ui.modal);

  const onSubmit = () => {
    const cartItem = data as CartItem;
    if (cartItem) {
      dispatch(removeItem(cartItem));
      window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
          items: [
            {
              item_name: cartItem.articleDesc,
              item_id: cartItem.articleCode,
              item_brand: cartItem.brand,
              item_category: cartItem.operatingCenterDesc,
              price: cartItem.priceList,
              quantity: cartItem.quantity,
            },
          ],
        },
      });
    }
    dispatch(hideModal());
  };

  return (
    <>
      {!isMobile && (
        <Flex justifyContent="flex-end" position="relative" top="14px" right="18px">
          <CloseIcon
            data-testid="close-delete-product-modal-icon"
            onClick={() => dispatch(hideModal())}
            cursor="pointer"
          />
        </Flex>
      )}
      <Flex
        paddingLeft={{ base: '36px', xl: '36px' }}
        paddingRight={{ base: '36px', xl: '36px' }}
        paddingBottom={{ base: '50px' }}
        flexDirection="column"
        justifyContent="space-between"
        data-testid="delete-product-modal-container"
        backgroundColor="background"
        height={isMobile ? '100dvh' : '485px'}
        borderRadius={{ xl: '6px' }}
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems={{ base: 'center' }}
          height="85%"
        >
          <DeleteProductIcon dataTestId="delete-product-icon" />
          <Text
            marginTop={{ base: '48px', md: '24px' }}
            data-testid="delete-product-label"
            fontSize={{ base: '25px' }}
            marginBottom={{ base: '48px', md: '24px' }}
            textAlign="center"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            color="grey900"
          >
            {textContent.cart.deleteProduct}
          </Text>
          <Text
            marginBottom={{ base: '8px' }}
            data-testid="delete-product-session-label"
            fontSize={{ base: '20px' }}
            textAlign="center"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            color="grey900"
          >
            {textContent.cart.deleteProductWarning}
          </Text>
          <Text
            data-testid="delete-product-with-continue-label"
            fontSize={{ base: '20px' }}
            textAlign="center"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            color="grey900"
          >
            {textContent.wishToContinue}
          </Text>
        </Flex>
        <Flex width={{ base: '100%' }} justifyContent={{ base: 'space-between' }} height="5%">
          <Button
            width={{ base: '45%' }}
            content={textContent.cancel}
            data-testid="delete-product-cancel-button"
            color="purple"
            backgroundColor="background"
            height={{ base: '40px' }}
            border="1px solid"
            borderColor="purple"
            onClick={() => {
              dispatch(hideModal());
            }}
            fontSize="14px"
          />
          <Button
            width={{ base: '45%' }}
            content={textContent.continue}
            data-testid="delete-product-confirm-button"
            backgroundColor="purple"
            color="lightgrey"
            height={{ base: '40px' }}
            onClick={onSubmit}
            _hover={{
              backgroundColor: { base: 'none', xl: 'darkPurple' },
            }}
            _active={{
              backgroundColor: { base: 'darkPurple', xl: 'none' },
            }}
            _disabled={{
              backgroundColor: { base: 'lightPurple', xl: 'lightPurple' },
              pointerEvents: 'none',
            }}
            fontSize="14px"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default DeleteProduct;
